<template>
  <!-- Base Card -->
  <div
    class="base-card"
    :style="getBaseCardStyle"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseCard',
  props: {
    // Base Card Width
    width: {
      type: [String, Number],
      required: false,
      default: 500
    },
    // Base Card Height
    // eslint-disable-next-line vue/require-default-prop
    height: {
      type: [String, Number],
      required: false
    }
  },
  computed: {
    // Get Base Card Style
    getBaseCardStyle () {
      return {
        width: this.width + 'px',
        height: this.height ? this.height + 'px' : 'max-content',
        maxWidth: '100vw'
      }
    }
  }
}
</script>

<style scoped lang="sass">
.base-card
  border-radius: 6px
  @apply bg-white border-grey-light p-6 shadow-custom

  @screen xs
    @apply m-4

  @screen md
    @apply mx-0
</style>

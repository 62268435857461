// import AxiosAuth from '@/config/AxiosAuthConfig'
import axios from 'axios'
import { getOAuthApiErrorMessage } from '../../utils/api'

// // Refresh Instance
// const RefreshAxios = axios.create({
//   baseURL: process.env.VUE_APP_AUTH_BASE_URL,
//   withCredentials: true
// })

// Create Axios Instance
const AxiosAuth = axios.create({
  baseURL: process.env.VUE_APP_AUTH_BASE_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json'
  }
})

// Login User
export const loginUser = async (loginPayload) => {
  const clientId = encodeURIComponent('minu-public-client')
  const redirectUri = encodeURIComponent('https://console.getminu.com/callback')
  const query = `client_id=${clientId}&redirect_uri=${redirectUri}`
  const url = `/oauth/authorize?${query}`
  const payload = {
    username: loginPayload.email,
    password: loginPayload.password
  }
  try {
    const { data } = await AxiosAuth.post(url, payload)
    return data
  } catch (e) {
    console.log(e)
    throw new Error(await getOAuthApiErrorMessage(e))
  }
}

<template>
  <!-- Base Button -->
  <button
    :type="type"
    class="base-button"
    :class="getBaseButtonClasses"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot v-if="!loading" />

    <BaseLoadingSpinner v-else />
  </button>
</template>

<script>
import BaseLoadingSpinner from './BaseLoadingSpinner'
export default {
  name: 'BaseButton',
  components: { BaseLoadingSpinner },
  props: {
    // Primary Style
    primary: {
      type: Boolean,
      required: false
    },
    // Is Button Disabled
    disabled: {
      type: Boolean,
      required: false
    },
    // Loading State
    loading: {
      type: Boolean,
      required: false
    },
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    // Text
    text: {
      type: Boolean,
      required: false
    },
    // Pill
    pill: {
      type: Boolean,
      required: false
    },
    // Outlined
    outlined: {
      type: Boolean,
      required: false
    },
    // Outlined small
    outlinedSm: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    // Get Button Classes
    getBaseButtonClasses () {
      const classes = []

      if (this.primary) classes.push('base-button--primary')
      if (this.pill) classes.push('base-button--pill')
      if (this.outlined) classes.push('base-button--outlined')
      if (this.outlinedSm) classes.push('base-button--outlined-sm')
      if (this.disabled) classes.push('base-button--disabled')
      if (this.text) classes.push('base-button--text')

      return classes
    }
  }
}
</script>

<style scoped lang="sass">
.base-button
  transition: all 0.1s ease-in
  white-space: nowrap
  @apply px-3 py-2 outline-none rounded-sm tracking-widest z-0

  &--primary
    @apply bg-brand text-white

  &--disabled
    background-color: #EAEAEA !important
    color: #888888 !important
    border: 1px solid grey
    @apply cursor-text

  &--text
    background: none !important
    border: none !important
    @apply px-0

  &--pill
    font-size: 14px
    @apply bg-brand text-white py-3 px-5 rounded-full

  &--outlined
    font-size: 14px
    @apply border border-brand bg-white text-brand py-3 px-5 rounded-full

  &--outlined-sm
    font-size: 12px
    @apply border border-brand bg-white text-brand py-2 px-3 rounded-full
</style>

<template>
  <!-- Login Card -->
  <BaseCard
    width="450"
    height="auto"
  >
    <!-- Title -->
    <div class="login-card--brand">
      minu
    </div>

    <!-- Alert -->
    <BaseAlert
      v-if="showAlert"
      type="custom"
      @close="showAlert = false"
    >
      {{ alertMessage }}
    </BaseAlert>

    <!-- Form -->
    <form
      class=""
      @submit.prevent="loginMethod"
    >
      <!-- Email -->
      <BaseInput
        v-model="loginForm.email"
        label="Email"
        class="pb-0"
        :error-message="getRequiredEmailMessage"
      />

      <!-- Password -->
      <BaseInput
        v-model="loginForm.password"
        label="Password"
        class="pb-0 mb-0"
        input-type="password"
        :error-message="getRequiredPasswordMessage"
      />

      <!-- Forgot Password -->
      <router-link
        tag="a"
        :to="{ name: 'ForgotPasswordView', query: { from: 'login' } }"
        class="text-xs flex font-bold text-brand pb-3 mt-0 pt-0"
      >
        Forgot your password?
      </router-link>

      <!-- Login Button -->
      <div class="text-center my-5">
        <BaseButton
          pill
          class="w-full"
          :disabled="loadingLogin"
          :loading="loadingLogin"
          type="submit"
        >
          Sign In
        </BaseButton>
      </div>

      <!-- Go to Sign Up Page -->
      <div class="text-xs text-center mt-1">
        Need an account?
        <router-link
          :to="{ name: 'ChooseAccountTypeView' }"
          class="font-bold text-brand"
        >
          Sign Up Here
        </router-link>
      </div>
    </form>
  </BaseCard>
</template>

<script>
import BaseCard from '../globals/BaseCard'
import BaseAlert from '../globals/BaseAlert'
import BaseInput from '../globals/BaseInput'
import BaseButton from '../globals/BaseButton'
import { validationMixin } from 'vuelidate'
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import { mapMutations } from 'vuex'
import { loginUser } from '@/api/AuthServices'

export default {
  name: 'LoginCard',
  components: { BaseButton, BaseInput, BaseAlert, BaseCard },
  mixins: [validationMixin],
  data () {
    return {
      loginForm: {},
      showAlert: false,
      loadingLogin: false,
      alertMessage: ''
    }
  },
  computed: {
    // Get Required Email Error
    getRequiredEmailMessage () {
      if (!this.$v.loginForm.$anyDirty) return ''
      if (!this.$v.loginForm.email.required) return 'Email is required'
      if (!this.$v.loginForm.email.email) return 'Email provided is not the right format'

      return null
    },
    // Get Required Password Error
    getRequiredPasswordMessage () {
      if (!this.$v.loginForm.$anyDirty) return ''
      if (!this.$v.loginForm.password.required) return 'Password is required'
      if (!this.$v.loginForm.password.minLength) return 'Password is too short'
      if (!this.$v.loginForm.password.maxLength) return 'Password is too long'

      return null
    }
  },
  validations: {
    loginForm: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(32)
      }
    }
  },
  methods: {
    ...mapMutations('AuthModule', ['LOGIN_USER']),
    // Login Method
    async loginMethod () {
      this.$v.$touch()

      this.loadingLogin = true
      if (!this.$v.loginForm.$invalid) {
        try {
          const response = await loginUser(this.loginForm)

          if (response.status === 'error') {
            this.alertMessage = response.message
            this.showAlert = true
          } else {
            this.LOGIN_USER(response.data)
            await this.$router.push('/dashboard')
          }
        } catch (e) {
          this.alertMessage = e.message
          this.showAlert = true
        } finally {
          this.loadingLogin = false
        }
      } else {
        this.loadingLogin = false
      }
    }
  }
}
</script>

<style scoped lang="sass">
  .login-card
    &--brand
      font-family: "All Round Gothic", sans-serif
      font-size: 30px
    @apply text-2xl text-center m-0

    &--brand-subtitle
      font-family: "Pulp Regular", sans-serif
      font-size: 13px
    @apply mx-auto text-center mb-10 mt-0
</style>

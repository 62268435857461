<template>
  <!-- Login View -->
  <MainContent>
    <!-- Login Card -->
    <LoginCard />
  </MainContent>
</template>

<script>
import MainContent from '@/components/hoc/MainContent'
import LoginCard from '@/components/login/LoginCard'

export default {
  name: 'LoginView',
  components: { LoginCard, MainContent }
}
</script>

<style scoped lang="sass">

</style>

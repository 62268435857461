<template>
  <!-- Main Content -->
  <div class="main-content">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'MainContent'
}
</script>

<style scoped lang="sass">
@import @/assets/css/mixins.sass

.main-content
  @apply bg-secondary flex flex-wrap justify-center

  @screen xs
    @apply mt-16 items-start

  @screen md
    @apply items-center mt-0

</style>


export const getOAuthApiErrorMessage = async (e) => {
  if (e.response &&
    e.response.data &&
    e.response.data.error &&
    e.response.data.error.details &&
    e.response.data.error.details.body &&
    e.response.data.error.details.body[0] &&
    e.response.data.error.details.body[0].message
  ) {
    return e.response.data.error.details.body[0].message
  } else if (e.response && e.response.data && e.response.data.error && e.response.data.error.message) {
    return e.response.data.error.message
  } else {
    return 'Network error'
  }
}

<template>
  <!-- Base Input -->
  <div class="base-input">
    <!-- Label -->
    <label
      class="base-input--label font-light"
    >
      {{ label }}
    </label>

    <div class="base-input--wrapper">
      <!-- Icon -->
      <i
        v-if="icon"
        class="base-input--icon"
        :class="icon"
      />

      <!-- Input -->
      <input
        :disabled="disabled"
        :class="getBaseInputClasses"
        :type="inputType"
        :value="value"
        :placeholder="placeholder"
        @input="updateValue"
      >
    </div>

    <!-- Error Message -->
    <div
      v-if="errorMessage"
      class="base-input--error-message"
    >
      {{ errorMessage }}
    </div>

    <!-- Hint -->
    <div
      v-else-if="hint"
      class="base-input--hint"
    >
      {{ hint }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    // Model
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },
    // Label
    label: {
      type: String,
      required: false,
      default: ''
    },
    // Input Type
    inputType: {
      type: String,
      required: false,
      default: 'text'
    },
    hasError: {
      type: Boolean,
      required: false
    },
    // Error Message
    errorMessage: {
      type: String,
      required: false,
      default: ''
    },
    // Input Placeholder
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    // Input Hint
    hint: {
      type: String,
      required: false,
      default: ''
    },
    icon: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false
    },
    classes: {
      type: String,
      required: false,
      default: 'base-input--input'
    }
  },
  computed: {
    // Get Base Input Classes
    getBaseInputClasses () {
      const classes = [this.classes]

      this.errorMessage || this.hasError ? classes.push('base-input--input--error') : classes.push('border border-grey')
      this.icon ? classes.push('pl-8') : classes.push('pl-2')

      return classes
    }
  },
  methods: {
    // Update Model Value
    updateValue (e) {
      if (this.inputType === 'number') {
        this.$emit('input', Number(e.target.value))
      } else {
        this.$emit('input', e.target.value)
      }
    }
  }
}
</script>

<style scoped lang="sass">
.base-input
  @apply mb-6 relative

  &--label
    @apply text-sm tracking-wide

  &--icon
    margin-left: 10px
    @apply absolute left-0 text-grey-darker

  &--wrapper
    height: 45px
    @apply bg-white flex items-center rounded-sm

  &--input
    line-height: 44px
    margin-top: 2px
    @apply outline-none w-full

    &--error
      transition: all 0.2s ease-in
      @apply border-danger border

  &--error-message
    transition: all 0.2s ease-in
    animation: shake 0.3s ease
    @apply absolute mt-1 text-danger text-xs

  &--hint
    transition: all 0.2s ease-in
    @apply absolute mt-1 text-grey-darker text-xs

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type=number]
  -moz-appearance: textfield
</style>

<template>
  <!-- Base Alert -->
  <div
    class="base-alert"
    :class="getBaseAlertClasses"
  >
    <i :class="`fas fa-${icon} base-alert--icon`" />

    <div class="base-alert--message">
      <slot />
    </div>

    <div class="base-alert--action">
      <slot name="action" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseAlert',
  props: {
    // Alert Type
    type: {
      type: String,
      required: false,
      default: 'error'
    },
    // Icon
    icon: {
      type: String,
      required: false,
      default: 'exclamation-circle'
    },
    duration: {
      type: Number,
      required: false,
      default: 2000
    }
  },
  computed: {
    // Base Alert Classes
    getBaseAlertClasses () {
      const classes = []

      if (this.type === 'error') classes.push('base-alert--error')
      if (this.type === 'warning') classes.push('base-alert--warning')
      if (this.type === 'custom') classes.push('base-alert--custom')

      return classes
    }
  },
  mounted () {
    setTimeout(() => {
      this.closeAlert()
    }, this.duration)
  },
  methods: {
    // Close Alert
    closeAlert () {
      this.$emit('close', false)
    }
  }
}
</script>

<style scoped lang="sass">
.base-alert
  animation: appearTop 0.2s ease-in
  width: max-content
  font-size: 13px
  @apply flex items-center px-5 py-2 rounded-sm mx-auto

  @screen xs
    @apply justify-center flex-col

  @screen md
    @apply flex-row

  &--icon
    @apply text-2xl text-grey

    @screen xs
      @apply mr-0

    @screen md
      @apply mr-6

  &--error
    @apply bg-danger text-white

  &--warning
    @apply bg-warning-light text-warning

  &--message
    @screen xs
      @apply mt-2 text-center w-full

    @screen md
      @apply mt-0 text-left

  &--action
    @apply text-brand

    @screen xs
      @apply mx-auto

    @screen md
      @apply ml-auto

  &--custom
    @apply bg-grey-lightest rounded-full text-black

@keyframes appearTop
  0%
    opacity: 0
    transform: translateY(-20px)

  100%
    opacity: 1
</style>
